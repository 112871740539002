// Imports
// -------
import { css, createGlobalStyle } from 'styled-components';
import Color from 'color';

// *** Theme Styling *** //
// ------
// 1. NOTE • Colours [Global, Social, Feedback, Brand]
// ------
// 2. NOTE • Spacing [Padding & Margin]
// ------
// 3. NOTE • Fonts [Type, Weight]
// ------
// 4. NOTE Grid [Breakpoints, Gutter, Max Grid Size]
// ------
// 5. NOTE • Easings [Bezzy, Global Ease]
// ------
// 6. NOTE • Magic Verticals
// ------
// 7. NOTE • No ScrollBars
// ------
// 8. NOTE • Alignments
// ------

// Exports
// ------
const brandColors = {
	primary: '#FF4444',
	secondary: '#1E1E1E',
}

export const theme = {
	// 1. ANCHOR Colours
	// ------
	colors: {
		global: {
			white: '#ffffff',
			white0: Color('#ffffff').rgb().alpha(0),
			black: '#000000',
			black0: Color('#000000').rgb().alpha(0),
		},

		social: {
			facebook: '#3b5998',
			twitter: '#1da1f2',
			creativeMarket: '#8ba753',
			slack: '#e9a820',
			skype: '#00aff0',
			instagram: '#833ab4',
			dribbble: '#ea4c89',
			behance: '#1769ff',
			linkedin: '#0077b5',
		},

		feedback: {
			positive: '#3adb76',
			negative: '#cc4b37',
			warning: '#ffae00',
		},

		brand: {
			bc1: brandColors.primary,
			bc1150: Color(brandColors.primary).mix(Color('black'), 0.5).hex(),
			bc1140: Color(brandColors.primary).mix(Color('black'), 0.4).hex(),
			bc1130: Color(brandColors.primary).mix(Color('black'), 0.3).hex(),
			bc1120: Color(brandColors.primary).mix(Color('black'), 0.2).hex(),
			bc1110: Color(brandColors.primary).mix(Color('black'), 0.1).hex(),
			bc190: Color(brandColors.primary).mix(Color('white'), 0.1).hex(),
			bc180: Color(brandColors.primary).mix(Color('white'), 0.2).hex(),
			bc170: Color(brandColors.primary).mix(Color('white'), 0.3).hex(),
			bc160: Color(brandColors.primary).mix(Color('white'), 0.4).hex(),
			bc150: Color(brandColors.primary).mix(Color('white'), 0.5).hex(),
			bc140: Color(brandColors.primary).mix(Color('white'), 0.6).hex(),
			bc130: Color(brandColors.primary).mix(Color('white'), 0.7).hex(),
			bc120: Color(brandColors.primary).mix(Color('white'), 0.8).hex(),
			bc110: Color(brandColors.primary).mix(Color('white'), 0.9).hex(),


			bc2: brandColors.secondary,
			bc290: Color(brandColors.secondary).mix(Color('white'), 0.1).hex(),
			bc280: Color(brandColors.secondary).mix(Color('white'), 0.2).hex(),
			bc270: Color(brandColors.secondary).mix(Color('white'), 0.3).hex(),
			bc260: Color(brandColors.secondary).mix(Color('white'), 0.4).hex(),
			bc250: Color(brandColors.secondary).mix(Color('white'), 0.5).hex(),
			bc240: Color(brandColors.secondary).mix(Color('white'), 0.6).hex(),
			bc230: Color(brandColors.secondary).mix(Color('white'), 0.7).hex(),
			bc220: Color(brandColors.secondary).mix(Color('white'), 0.8).hex(),
			bc210: Color(brandColors.secondary).mix(Color('white'), 0.9).hex(),
		}
	},

	// 2. ANCHOR • Padding & Margin
	// ------
	space: {
		mpad: '1.2rem', // Mobile Padding
		small: '12rem', // Small
		medium: '18rem', // Medium
		large: '24rem', // Large
	},

	// 3. ANCHOR • Fonts
	// ------
	font: {
		type: {
			heading: '"TT Interphases"',
			mono: '"TT Interphases"',
			hand: '"TT Interphases"',
			body: '"TT Interphases"',
		},
		weight: {
			light: 300,
			reg: 400,
			semi: 500,
			bold: 600,
		}
	},

	// 4. ANCHOR • Breakpoints
	// ------
	grid: {
		breakpoints: {
			small: '0px',
			smedium: '370px',
			medium: '700px',
			large: '1024px',
			xlarge: '1200px',
			xxlarge: '1400px',
			huge: '1600px',
			uber: '1800px',
		},

		gutter: {
			small: '2.4rem',
			large: '3.6rem',
		},

		maxSize: '1440px'
	},

	// 5. ANCHOR • Easings
	// ------
	easing: {
		bezzy: 'cubic-bezier(0.8, 0, 0, 1)',
		ease: 'all .3s ease-in-out',
	},

	// 6. ANCHOR • Magic Verticals
	// ------
	vh: css`
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	`,

	vhs: css`
		height: 100vh;
		height: calc(var(--vhs, 1vh) * 100);
	`,

	// 7. ANCHOR • No ScrollBars
	// ------
	noscrollbars: css`
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE 10+ */

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent; /* Chrome/Safari/Webkit */
		}
	`,

	// 8. ANCHOR • Alignments
	// ------
	valign: css`
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	`,

	valignReset: css`
		position: relative;
		top: auto;
		transform: none;
	`,

	talign: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,

	talignReset: css`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
	`,
};

export const GlobalStyle = createGlobalStyle`
    body {
        background: ${theme.colors.brand.bc2};
    }
	
    * {
        color: ${theme.colors.global.white};
		/* cursor: none !important; */
    }
`;

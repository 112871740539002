// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Shared Heading Styles
// --------------
const sharedHeadingStyles = (props) => css`
	font-weight: ${props.theme.font.weight.light};
	font-family: ${props.theme.font.type.heading};
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = css`
	${sharedHeadingStyles}
	font-size: 3rem;
	line-height: 1.2;

	${breakpointUp.smedium`
		font-size: 3.8rem;
	`}

	${breakpointUp.medium`
		font-size: 6rem;
	`}

	${breakpointUp.xxlarge`
		font-size: 8.4rem;
		letter-spacing: -0.18rem;
	`}
`;

export const h2Styles = css`
	${sharedHeadingStyles}
	font-size: 3.2rem;
	line-height: 1.2;
	letter-spacing: -0.09rem;

    ${breakpointUp.xlarge` font-size: 4.4rem; `}
`;

export const h3Styles = css`
	${sharedHeadingStyles}
	font-size: 2.8rem;
	line-height: 1.2;
	letter-spacing: -0.09rem;

    ${breakpointUp.xlarge` font-size: 3.6rem; `}
`;

export const h4Styles = css`
	${sharedHeadingStyles}
	font-size: 2.4rem;
	line-height: 1.2;
	letter-spacing: -0.09rem;

	${breakpointUp.large` font-size: 3rem; `}
`;

export const h5Styles = css`
	${sharedHeadingStyles}
	font-size: 2rem;
	line-height: 1.4;
	letter-spacing: -0.04rem;

	${breakpointUp.large` font-size: 2.4rem; `}
`;

export const h6Styles = css`
	${sharedHeadingStyles}
	font-size: 1.6rem;
	line-height: 1.4;
	letter-spacing: -0.18rem;

	${breakpointUp.large` font-size: 2.4rem; `}
`;

// --------------
// 3. Shared Body Styles
// --------------
const sharedBodyStyles = (props) => css`
	font-family: ${props.theme.font.type.body};
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = (props) => css`
	${sharedBodyStyles}
	color: ${props.theme.colors.brand.bc260};
	font-weight: ${props.theme.font.weight.reg};
	font-size: 1.4rem;
	line-height: 1.6;
	letter-spacing: -0.04rem;

    ${breakpointUp.xlarge`
		font-size: 1.8rem;
	`}
`;

export const spanStyles = (props) => css`
	${sharedBodyStyles}
	font-weight: ${props.theme.font.weight.reg};
	font-size: 1.2rem;
	line-height: 1.6;
	letter-spacing: -0.03rem;

	${breakpointUp.xlarge` font-size: 1.4rem; `}
`;

export const emStyles = (props) => css`
	${sharedBodyStyles}
	display: block;
	font-style: normal;
	font-weight: ${props.theme.font.weight.bold};
	font-size: 1rem;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 1px;

	${breakpointUp.xlarge` font-size: 1.2rem; `}
`;

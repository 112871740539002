// Imports
// ------------
import React, { useEffect, useRef, useState } from 'react';
import gsap, { Power1 } from 'gsap';
import { theme } from '@theme';
import { loadingState } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { Jacket, Number } from './styles';
import { AnimatePresence } from 'framer-motion';

// Component
// ------------
const Loader = () => {
    // NOTE • States
    const [isDone, setIsDone] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);

    // NOTE • Refs
    const percentage = useRef();
    const background = useRef();

    // NOTE • Loader
    const handleLoader = action(() => {
        loadingState.isFinished = true;
        setIsDone(true);
    });

    useEffect(() => {
        gsap.from(percentage.current, {
            textContent: 0,
            duration: 2,
            ease: Power1.easeIn,
            snap: { textContent: 1 },
        });
        gsap.to(percentage.current, {
            opacity: 1,
            ease: Power1.easeIn,
        });
        gsap.to(background.current, {
            duration: 2,
            ease: Power1.easeIn,
            background: theme.colors.brand.bc2,
            onComplete: handleLoader,
        });
    }, [])

    // NOT • Destroy Loader code when finished
    useEffect(() => {
        let timer;

        const destroy = () => {
            timer = setTimeout(() => {
                setIsRemoved(true)
            }, 500);
        }


        isDone && destroy();

        return () => {
            clearTimeout(timer);
        }
    }, [isDone])

    return (
        <AnimatePresence>
            {!isRemoved &&
                <Jacket
                    ref={background}
                    initial={{ opacity: 1 }}
                    animate={{
                        opacity: 0,
                        transition: { delay: 1.5 }
                    }}
                >
                    <span>
                        <Number ref={percentage}>100</Number>
                    </span>
                </Jacket>
            }
        </AnimatePresence>
    );
}

export default observer(Loader);
// Imports
// ------------
import React from 'react';
import CookieConsent from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const CookieBar = ({ location }) => (
    <Jacket>
        <CookieConsent
            buttonText="Accept &amp; Close"
            enableDeclineButton={false}
            cookieName="gatsby-gdpr-google-tagmanager"
            expires={150}
            disableStyles={true}
            onAccept={() => initializeAndTrack(location)}
        >
            By using this website you agree to our cookie policy
        </CookieConsent>
    </Jacket>
);

export default CookieBar;
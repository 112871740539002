// Imports
// ------
import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

// Component
// ------
const Seo = () => {
    // NOTE • Data
    const data = useStaticQuery(graphql`
		query SEO {
			global: datoCmsSite {
				seo: globalSeo {
					facebook: facebookPageUrl
					twitter: twitterAccount

					meta: fallbackSeo {
						title
						image {
							url
						}
						desc: description
						card: twitterCard
					}
				}
			}
		}
	`);

    return (
        <Helmet title={data.global.seo.meta.title} htmlAttributes={{ lang: 'en' }}>
            <meta charSet="utf-8" />
            <title>{data.global.seo.meta.title}</title>
            <meta name='description' content={data.global.seo.meta.desc} />

            {/* ANCHOR • OpenGraph Meta */}
            <meta property='og:title' content={data.global.seo.meta.title} />
            <meta property='og:description' content={data.global.seo.meta.desc} />
            <meta property='og:image' content={data.global.seo.meta.image.url} />
            <meta property='og:type' content={data.global.seo.meta.card} />

            {/* {schema && <script type='application/ld+json'>{schema}</script>} */}
        </Helmet>
    );
}

export default Seo;

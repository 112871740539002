// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { pStyles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled.div(props => css`
    position: fixed;
    z-index: 99;
    top: 10.8rem;
    left: 3.6rem;

    ${breakup.large`
        top: 16.2rem;
        left: 0;
    `}

    .CookieConsent {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-start;
        background: ${props.theme.colors.global.white};

        padding: 2.4rem;
        max-width: 20.8rem;

        ${breakup.large`
            flex-flow: row;
            align-items: center;
            padding: 2.4rem 2.4rem 2.4rem 6rem;
            max-width: 100%;
        `}

        > div {
            ${pStyles}
            color: ${props.theme.colors.brand.bc2};
        }

        #rcc-confirm-button {
            ${pStyles}
            color: ${props.theme.colors.brand.bc1};

            margin: 1.2rem 0 0 0;
            padding: 0;
            background: transparent;
            cursor: pointer;
            transition: ${props.theme.easing.ease};

            ${breakup.large`
                margin: 0 0 0 2.4rem;
            `}

            &:hover {
                ${breakup.large`
                    color: ${props.theme.colors.brand.bc1120};
                `}
            }
        }
    }
`);

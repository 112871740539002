// Imports
// ------------
import styled, { css } from 'styled-components';
import { h1Styles } from '@tackl-type';
import { motion } from 'framer-motion'

// Exports
// ------------
export const Jacket = styled(motion.section)(props => css`
    position: fixed;
    z-index: 100;
    top: 0; left: 0; right: 0; bottom: 0;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${props.theme.colors.global.white};
    opacity: ${props.isDone ? 0 : 1};
    transition: opacity 0.5s ease-in-out;
    transition-delay: 0.25s;
`);

export const Number = styled.span`
    ${h1Styles}
    color: ${props => props.theme.colors.global.white};
    opacity: 0;
    mix-blend-mode: difference;

    &:after {
        content: "%";
    }
`;
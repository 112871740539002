// Imports
// ------
import React, { useEffect } from 'react';
import Loader from '@parts/Loader';
import Seo from '@parts/Seo';
import CookieBar from '@parts/CookieBar';
import { ThemeProvider } from 'styled-components';
import { useWindowSize } from 'react-use';
import { theme, GlobalStyle } from '@theme';

// Styles
// ------
import '@css/global.css';

// Component
// ------
const Layout = ({ children, location }) => {
	// NOTE • Window Size Checker
	const { width } = useWindowSize();

	// NOTE • OnLoad: 100vh Fix
	useEffect(() => {
		const setHeightVar = () => {
			let vh = document.documentElement.clientHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};

		// Init
		setHeightVar();

		const resizeChecker = () => {
			if (width > 768) {
				setHeightVar();
			}
		}

		// Initialize resize
		window.addEventListener('resize', resizeChecker);

		return () => {
			window.removeEventListener('resize', resizeChecker);
		};
	}, []);

	return (
		<>
			<ThemeProvider theme={theme}>
				<Loader />
				<Seo />
				<CookieBar location={location} />
				{children}
			</ThemeProvider>

			<GlobalStyle theme={theme} />
		</>
	);
};


export default Layout;
